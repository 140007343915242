<template>
  <div class="page-header">
    <div class="container-fluid">
      <breadcrumbs></breadcrumbs>
      <div class="d-lg-flex align-items-end justify-content-between">
        <div>
          <h1 class="page-title">{{ title }}</h1>
          <h3 class="page-title-sub">
            <router-link to="/pages/applications">{{ subTitle }}</router-link>
          </h3>
          <ul class="list-inline mb-1">
            <li class="list-inline-item"><span class="text-muted">Package Type:</span> <strong>Land Title</strong></li>
            <li class="list-inline-item">
              <span class="text-muted">Status:</span>
              <strong> Deferred until January 31, 2025</strong>
            </li>
          </ul>
        </div>
        <ul class="nav mt-3-md mt-lg-1 d-print-none">
          <li class="nav-item">
            <button class="btn btn-md btn-secondary mr-2">Download Package</button>
          </li>
          <li class="nav-item">
            <a class="btn btn-primary btn-md mt-1 mt-sm-0 px-2" href="javascript:void(0)">
              Submit Package Now
              <svg class="icon icon-arrow_cta"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
  },

  data() {
    return {};
  },
};
</script>
