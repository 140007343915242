<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <!-- Main package content -->
      <div class="row mt-md-3">
        <!-- Step wizard -->
        <div class="col-md-3 mb-2 mb-md-4">
          <nav class="nav flex-column mb-3" role="navigation">
            <router-link class="nav-link" to="/pages/applications">Package</router-link>
            <router-link class="nav-link" to="/pages/info-history">Info &amp; History</router-link>
            <router-link class="nav-link" to="/pages/package-validation">
              Package Validation
              <span class="badge badge-pill badge-danger ml-1">2</span>
              <span class="badge badge-pill badge-warning ml-1">1</span>
            </router-link>
            <a class="nav-link" href="javascript:void(0)">Notifications</a>
          </nav>
        </div>

        <!-- Data entry form -->
        <div class="col-md-9 col-lg-8">
          <h2 class="mb-3">
            Package Validation
            <a href="#" data-toggle="popover" data-placement="top" data-title="What is a Validation Error?" data-content="A technical or legal requirement that must be fixed beforesubmitting." v-popover>
              <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
            </a>
          </h2>

          <!-- Validate prompt -->
          <div class="card bg-light mb-4">
            <div class="card-body">
              <h3 class="mb-3">Validate your package to confirm it is ready for submission.</h3>
              <Button class="btn btn-tertiary btn-lg">Validate Package</Button>
              <div class="mt-2">
                Last validated: <br />
                <strong>January 1, 2024 11:34 AM</strong>
              </div>
            </div>
          </div>

          <!-- Summary  -->
          <div class="card bg-light mb-4">
            <div class="card-header bg-medium">
              <h4 class="mb-0">Package Validation Summary</h4>
            </div>
            <div class="card-body">
              <table>
                <tr>
                  <td class="text-nowrap align-top pr-3">
                    <svg class="icon icon-error text-danger mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-error"></use></svg> Errors: <strong>2</strong>
                  </td>
                  <td class="pb-2"><small>All errors must be addressed before you can E-sign a document or submit a package.</small></td>
                </tr>
                <tr>
                  <td class="text-nowrap align-top pr-3">
                    <svg class="icon icon-warning text-warning mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-warning"></use></svg> Warnings: <strong>1</strong>
                  </td>
                  <td><small>Warnings will allow you to E-sign a document or submit a package, but you must acknowledge whether you will fix or override them.</small></td>
                </tr>
              </table>
            </div>
          </div>

          <!-- Validation results -->
          <div class="card mb-4">
            <ul class="list-group list-group-flush mb-0">
              <li class="list-group-item">
                <div class="d-flex">
                  <svg class="icon icon-error text-danger">
                    <use xlink:href="/icons/symbol-defs.svg#icon-error"></use>
                  </svg>
                  <div class="ml-2">
                    <p>The package sequence in Package Management is incorrect. [12345]</p>
                    <button class="btn btn-md btn-tertiary">Fix</button>
                  </div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="d-flex">
                  <svg class="icon icon-error text-danger">
                    <use xlink:href="/icons/symbol-defs.svg#icon-error"></use>
                  </svg>
                  <div class="ml-2">
                    <p>Document 1 [Form C (Release).pdf] - Charge number/Document Reference CA123 is either invalid or inactive. [1036]</p>
                    <button class="btn btn-md btn-tertiary">Fix</button>
                  </div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="d-flex">
                  <svg class="icon icon-error text-warning"><use xlink:href="/icons/symbol-defs.svg#icon-warning"></use></svg>
                  <div class="ml-2">
                    <p>The conveyance of a Strata Lot should be accompanied by a Declaration application having a Form F Statement. [1124]</p>

                    <div class="d-flex align-items-center">
                      <button :disabled="override" class="btn btn-md btn-tertiary mr-2">Fix</button>
                      <div class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" id="overrideWarning" v-model="override" />
                        <label class="custom-control-label" for="overrideWarning">Override warning</label>
                      </div>
                    </div>

                    <!-- Override warning -->
                    <template v-if="overrideAdded == false">
                      <form class="mt-2" action="" v-if="override == true">
                        <div class="form-group">
                          <label for="overridermation">
                            Briefly describe your reason to override this warning. (optional)
                            <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" class="text-tertiary">
                              <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg> </a
                            ><br />
                            Do not enter personal information.
                          </label>
                          <textarea class="form-control" name="" id="" cols="30" rows="2" v-model="overrideReason"></textarea>
                        </div>
                        <button class="btn btn-md btn-tertiary" @click.prevent="saveInfo()" type="submit">Done</button>
                        <button class="btn btn-md btn-link" @click.prevent="override = false">Cancel</button>
                      </form>
                    </template>

                    <div class="mt-2" v-if="overrideAdded == true">
                      <strong>Reason for override</strong>
                      <p class="mb-0">{{ overrideReason }}</p>
                      <a href="#" @click.prevent="overrideAdded = false">Edit</a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- Action buttons -->
          <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-end d-print-none">
              <button disabled class="btn btn-primary">
                Review Submission <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PackageHeader from "../../components/PackageHeader.vue";
import PackageNavigation from "../../components/PackageNavigation.vue";

export default {
  components: {
    "package-header": PackageHeader,
    "package-navigation": PackageNavigation,
  },

  data() {
    return {
      overrideReason: "",
      overrideReason: "",
      override: false,
      overrideAdded: false,
    };
  },

  methods: {
    saveInfo: function () {
      this.overrideReason = this.overrideReason;
      this.overrideAdded = true;
    },
  },
};
</script>
