<template>
  <main>
    <package-header title="Submit Package" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Back to package</span>
              <span class="d-sm-none">Back</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <!-- Applications to be submitted -->
          <div class="card mb-4">
            <div class="card-header bg-light">
              <h3 class="mb-0">Applications to be Submitted</h3>
            </div>

            <div class="card-body p-0">
              <p class="lead pt-2 pl-2">File Reference: {Package Name}</p>

              <table class="table border-top mb-0">
                <thead>
                  <tr>
                    <th class="pl-2">#</th>
                    <th scope="col">File Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Transaction Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in tableItems" :key="index">
                    <td class="pl-2">{{ index }}.</td>
                    <td>{{ item.a }}</td>
                    <td>{{ item.b }}</td>
                    <td>{{ item.c }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Submission time -->
          <div class="card mb-4">
            <div class="card-header bg-light">
              <h3 class="mb-0">Submission Time</h3>
            </div>
            <card class="card-body">
              <div class="custom-control custom-radio">
                <input type="radio" id="submitNow" name="submit" class="custom-control-input" />
                <label class="custom-control-label" for="submitNow">Submit package immediately </label>
              </div>
              <div class="d-md-flex align-items-baseline">
                <div class="custom-control custom-radio">
                  <input type="radio" id="submitLater" name="submit" class="custom-control-input" />
                  <label class="custom-control-label" for="submitLater">Defer submission until HH:MM am/pm PST on</label>
                </div>
                <div class="form-group col-5 col-lg-3 mb-0 ml-2 ml-md-0">
                  <label for="" class="sr-only">Single date</label>
                  <div class="input-group">
                    <svg class="icon icon-inline-right icon-date position-absolute">
                      <use xlink:href="/icons/symbol-defs.svg#icon-date"></use>
                    </svg>
                    <input type="text" class="form-control" value="" />
                  </div>
                  <small class="form-text text-muted">dd/mm/yyyy</small>
                </div>
              </div>
            </card>
          </div>

          <!-- Title searches  -->
          <div class="card">
            <div class="card-header bg-light">
              <h3 class="mb-0">Title Searches</h3>
            </div>
            <div class="card-body">
              <div class="custom-control custom-checkbox">
                <input v-model="orderTitleSearch" id="checkInvalid" type="checkbox" class="custom-control-input" />
                <label for="checkInvalid" class="custom-control-label">
                  Order a Post-Title Search
                  <small class="form-text text-muted">* You will be charged upon fulfillment</small>
                </label>
              </div>

              <div class="mt-2 ml-4" v-if="orderTitleSearch">
                <div class="form-group d-inline-flex align-items-center">
                  <div class="input-group w-auto col-4 col-md-3 pl-0">
                    <div class="input-group-prepend">
                      <button class="btn btn-sm btn-secondary">-</button>
                    </div>
                    <input type="text" class="form-control form-control-sm" id="exampleInputAmount" value="10" />
                    <div class="input-group-append">
                      <button class="btn btn-sm btn-secondary">+</button>
                    </div>
                  </div>
                  <label> minutes after package is received by LTSA</label>
                </div>

                <div class="custom-control custom-checkbox">
                  <input id="includeCancelledCharges" type="checkbox" class="custom-control-input" />
                  <label for="includeCancelledCharges" class="custom-control-label"> Include cancelled charges </label>
                </div>

                <p class="mt-2 mb-1">Select item(s) for title search. They will be conducted and processed for payment after the selected amount of time.</p>
                <table class="table border mb-0">
                  <thead>
                    <tr>
                      <th>
                        <div class="custom-control custom-checkbox">
                          <input id="selectAll" type="checkbox" class="custom-control-input" />
                          <label for="selectAll" class="custom-control-label pl-2"><span class="sr-only">Select all applications</span></label>
                        </div>
                      </th>
                      <th>PID</th>
                      <th>Short Legal Description</th>
                      <th>Title</th>
                      <th>Registered Owner</th>
                      <th class="text-right">Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in orderTableItems" :key="index">
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input :id="'application' + index" type="checkbox" class="custom-control-input" />
                          <label :for="'application' + index" class="custom-control-label pl-2"
                            ><span class="sr-only">select application {{ item.b }}</span></label
                          >
                        </div>
                      </td>
                      <td class="text-nowrap">{{ item.a }}</td>
                      <td>{{ item.b }}</td>
                      <td>{{ item.c }}</td>
                      <td>{{ item.d }}</td>
                      <td class="text-right">{{ item.e }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-between border-left border-bottom border-right bg-medium pt-1 px-1">
                  <strong>Total</strong>
                  <h3 class="mb-1">$0.00</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Fee Summary -->
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Create Package"></help-panel>
  </main>
</template>

<script>
import PackageHeader from "../../components/PackageHeader.vue";
import HelpPanel from "../../components/HelpPanel.vue";

export default {
  components: {
    "package-header": PackageHeader,
    "help-panel": HelpPanel,
  },

  data() {
    return {
      orderTitleSearch: true,
      tableItems: [
        {
          a: "Form A",
          b: "Form A",
          c: "Freehold Transfer",
        },
        {
          a: "PTT28",
          b: "Property Transfer Tax Return",
          c: "Property Transfer Tax Return",
        },
        {
          a: "L-001-686-237-TD",
          b: "Transparency Declaration",
          c: "Transparency Declaration",
        },
        {
          a: "Form B",
          b: "Form B",
          c: "Mortgage",
        },
      ],
      orderTableItems: [
        {
          a: "000-000-108",
          b: "s/11166/////2",
          c: "CA5555017 V",
          d: "PARK, SARAH",
          e: "$12.82",
        },
        {
          a: "000-000-108",
          b: "CA5555018 VI ",
          c: "Property Transfer Tax Return",
          d: "TRUHAM, PARKER",
          e: "$12.82",
        },
        {
          a: "000-000-019",
          b: "s/11166/////1",
          c: "TX5945366 VI",
          d: "SMART, BECKY",
          e: "$12.82",
        },
      ],
    };
  },
};
</script>
