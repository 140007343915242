<template>
  <main>
    <div class="page-header">
      <div class="container-fluid">
        <breadcrumbs></breadcrumbs>
        <div class="d-md-flex align-items-end justify-content-between">
          <h1 class="page-title mb-3 mb-md-2">View All Packages</h1>
          <a class="btn btn-lg btn-info mb-2" href="#"
            ><svg class="icon icon-add"><use xlink:href="/icons/symbol-defs.svg#icon-add"></use></svg> Create a New Package</a
          >
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="card mb-4 pt-1 pb-0 px-md-3">
            <div class="card-body col-lg-8 col-md-12">
              <div class="row mb-3">
                <div class="col">
                  <h4 class="mb-1">Search for a package by file reference, description, or creator</h4>
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search..." />
                    <div class="input-group-append">
                      <button class="btn btn-primary btn-narrow" type="button">
                        <svg class="icon icon-search"><use xlink:href="/icons/symbol-defs.svg#icon-search"></use></svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-sm-auto">
                  <h5 class="mb-1">Filter by</h5>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input" checked />
                    <label class="custom-control-label" for="customRadioInline1">My Own</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input" />
                    <label class="custom-control-label" for="customRadioInline2">My Organization</label>
                  </div>
                </div>

                <div class="col-sm-auto mt-2 mt-md-0">
                  <h5 class="mb-1">Package type</h5>
                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" id="customcheckboxInline1" name="customcheckboxInline1" class="custom-control-input" />
                    <label class="custom-control-label" for="customcheckboxInline1">LT</label>
                  </div>
                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" id="customcheckboxInline2" name="customcheckboxInline1" class="custom-control-input" />
                    <label class="custom-control-label" for="customcheckboxInline2">SG</label>
                  </div>
                </div>
              </div>

              <div class="collapse" id="moreFilters">
                <div class="pb-2 pt-1">
                  <div class="row">
                    <div class="col-sm-6">
                      <h5 class="mb-1">LT Status</h5>
                      <div class="row">
                        <div class="col-auto">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT1" name="LT1" class="custom-control-input" />
                            <label class="custom-control-label" for="LT1">Unsubmitted</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT2" name="LT2" class="custom-control-input" />
                            <label class="custom-control-label" for="LT2">Registered</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT3" name="LT3" class="custom-control-input" />
                            <label class="custom-control-label" for="LT3">Pending</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT4" name="LT4" class="custom-control-input" />
                            <label class="custom-control-label" for="LT4">Accepted</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT5" name="LT5" class="custom-control-input" />
                            <label class="custom-control-label" for="LT5">Declined</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT6" name="LT6" class="custom-control-input" />
                            <label class="custom-control-label" for="LT6">Deferred</label>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT7" name="LT7" class="custom-control-input" />
                            <label class="custom-control-label" for="LT7">Defected</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT8" name="LT8" class="custom-control-input" />
                            <label class="custom-control-label" for="LT8">Withdrawn</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT9" name="LT9" class="custom-control-input" />
                            <label class="custom-control-label" for="LT9">Cancelled</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT10" name="LT10" class="custom-control-input" />
                            <label class="custom-control-label" for="LT10">Queued for review</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT11" name="LT11" class="custom-control-input" />
                            <label class="custom-control-label" for="LT11">Rejected</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="LT12" name="LT12" class="custom-control-input" />
                            <label class="custom-control-label" for="LT12">Requires user action</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6 mt-2 mt-sm-0">
                      <h5 class="mb-1">SG Status</h5>
                      <div class="row">
                        <div class="col-auto">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="SG1" name="SG1" class="custom-control-input" />
                            <label class="custom-control-label" for="SG1">Unsubmitted</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="SG2" name="SG2" class="custom-control-input" />
                            <label class="custom-control-label" for="SG2">Registered</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="SG3" name="SG3" class="custom-control-input" />
                            <label class="custom-control-label" for="SG3">Pending</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="SG4" name="SG4" class="custom-control-input" />
                            <label class="custom-control-label" for="SG4">Accepted</label>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="SG7" name="SG7" class="custom-control-input" />
                            <label class="custom-control-label" for="SG7">Defected</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="SG8" name="SG8" class="custom-control-input" />
                            <label class="custom-control-label" for="SG8">Withdrawn</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="SG9" name="SG9" class="custom-control-input" />
                            <label class="custom-control-label" for="SG9">Cancelled</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="SG10" name="SG10" class="custom-control-input" />
                            <label class="custom-control-label" for="SG10">Queued for review</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col">
                      <h5 class="mb-1">Date Modified</h5>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="date1" name="date" class="custom-control-input" />
                        <label class="custom-control-label" for="date1">Last 24 hours</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="date2" name="date" class="custom-control-input" />
                        <label class="custom-control-label" for="date2">Last 7 days</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="date3" name="date" class="custom-control-input" />
                        <label class="custom-control-label" for="date3">Last 2 weeks</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="date4" name="date" class="custom-control-input" />
                        <label class="custom-control-label" for="date4">Last 12 months</label>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col">
                      <h5 class="mb-1">Custom Date Range</h5>
                      <div class="form-group col-md-8 px-0">
                        <div class="input-group">
                          <svg class="icon icon-inline-right icon-date position-absolute"><use xlink:href="/icons/symbol-defs.svg#icon-date"></use></svg>
                          <input type="text" class="form-control" @focus="datepicker = !datepicker" @blur="datepicker = !datepicker" />
                          <DatePicker v-if="datepicker"></DatePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-sm-flex justify-content-between mt-1">
                <button class="btn btn-link btn-narrow pl-0 mb-1 mb-sm-0" data-toggle="collapse" data-target="#moreFilters" aria-expanded="false" aria-controls="moreFilters" v-on:click="isOpen = !isOpen">
                  <span v-show="!isOpen">More Filters</span>
                  <span v-show="isOpen">Less Filters</span>
                  <svg class="icon icon-add" v-show="!isOpen"><use xlink:href="/icons/symbol-defs.svg#icon-add"></use></svg>
                  <svg class="icon icon-close" v-show="isOpen"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                </button>
                <div class="d-flex justify-content-between flex-row-reverse d-sm-block">
                  <button class="btn btn-link pr-0 pr-sm-2">Reset Filters</button>
                  <button class="btn btn-primary">Show Packages</button>
                </div>
              </div>
            </div>
          </div>

          <div class="btn-toolbar sticky-top bg-white mb-1 pb-1" v-show="checked > 0">
            <div class="btn-group">
              <svg class="icon icon-corner-left-down"><use xlink:href="/icons/symbol-defs.svg#icon-corner-left-down"></use></svg>
            </div>
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-secondary">Download</button>
              <button type="button" class="btn btn-secondary">Print</button>
              <button type="button" class="btn btn-danger">Delete</button>
            </div>
          </div>

          <div class="table table-stacked">
            <table class="table table-hover table-select-row green spacer-lg table-brand-secondary">
              <thead>
                <tr>
                  <th scope="row">
                    <input type="checkbox" id="th" />
                  </th>
                  <th scope="col">Type</th>
                  <th scope="col" class="active-sort">File Reference <span class="table-sort"></span></th>
                  <th scope="col">Description <span class="table-sort"></span></th>
                  <th scope="col">Created By <span class="table-sort"></span></th>
                  <th scope="col">
                    Modified
                    <span class="table-sort"></span>
                    <a class="text-white" href="#" data-toggle="popover" data-placement="left" data-title="Help Text" data-content="This is an example of some help text" v-popover>
                      <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                    </a>
                  </th>
                  <th scope="col">Status <span class="table-sort"></span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableItems">
                  <td scope="row">
                    <input type="checkbox" :id="index" @click="checkbox($event)" />
                  </td>
                  <td data-header="Type">
                    <span class="td-content"
                      ><a
                        ><strong>{{ item.g }}</strong></a
                      ></span
                    >
                  </td>
                  <td data-header="File Reference">
                    <span class="td-content"
                      ><a href="javascript:void(0)"
                        ><strong>{{ item.a }}</strong></a
                      ></span
                    >
                  </td>
                  <td data-header="Description">
                    <span class="td-content">{{ item.b }}</span>
                  </td>
                  <td data-header="Created by">
                    <span class="td-content">{{ item.c }}</span>
                  </td>
                  <td data-header="Modified">
                    <span class="td-content">{{ item.d }}</span>
                  </td>
                  <td data-header="Status">
                    <span class="td-content">{{ item.f }} <span v-if="item.e" :class="item.e">&nbsp;</span></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <nav class="pt-4" aria-label="Page navigation">
            <div class="d-md-flex flex-row align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <span class="pr-2">View:</span>
                <ul class="pagination m-0">
                  <li class="page-item"><a class="page-link" href="javascript:void(0)">20</a></li>
                  <li class="page-item"><a class="page-link" href="javascript:void(0)">50</a></li>
                  <li class="page-item"><a class="page-link" href="javascript:void(0)">100</a></li>
                </ul>
              </div>

              <ul class="pagination mt-3 mt-md-0">
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)" aria-label="First">First</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)" aria-label="First">Previous</a>
                </li>
                <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)" aria-label="Next"> Next </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)" aria-label="Next"> Last </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="View All Packages"></help-panel>
  </main>
</template>

<script>
import HelpPanel from "../../components/HelpPanel.vue";
import DatePicker from "../../components/DatePicker";

export default {
  components: {
    "help-panel": HelpPanel,
    DatePicker,
  },

  name: "view-all-packages",
  data() {
    return {
      checked: 0,
      isOpen: false,
      datepicker: false,
      tableItems: [
        {
          a: "12801 Anderson",
          b: "Purchase of strata title",
          c: "P. Schwartzman",
          d: "Sep 28 2017",
          e: "",
          f: "Rejected",
          g: "LT",
        },
        {
          a: "Jiles/Roberts",
          b: "Mortgage release",
          c: "P. Schwartzman",
          d: "Sep 17 2107",
          e: "badge badge-pill badge-danger",
          f: "Defected",
          g: "LT",
        },
        {
          a: "12764 Smith",
          b: "Bylaw road closure and transfer of title",
          c: "P. Schwartzman",
          d: "Sep 17 2017",
          e: "",
          f: "Unsubmitted",
          g: "SG",
        },
        {
          a: "12805 Terries",
          b: "Mortgage",
          c: "P. Schwartzman",
          d: "Sep 04 2017",
          e: "",
          f: "Pending",
          g: "LT",
        },
        {
          a: "12900 Jones",
          b: "Release of court order and mortgage",
          c: "P. Schwartzman",
          d: "Sep 02 2017",
          e: "",
          f: "Completed",
          g: "SG",
        },
        {
          a: "12907 Chan",
          b: "-",
          c: "P. Schwartzman",
          d: "Aug 28 2017",
          e: "",
          f: "Deferred",
          g: "SG",
        },
        {
          a: "Jiles/Roberts",
          b: "Mortgage release",
          c: "P. Schwartzman",
          d: "Sep 17 2107",
          e: "badge badge-pill badge-danger",
          f: "Requires action",
          g: "LT",
        },
        {
          a: "12801 Anderson",
          b: "Purchase of strata title",
          c: "P. Schwartzman",
          d: "Sep 28 2017",
          e: "",
          f: "Declined",
          g: "LT",
        },
        {
          a: "12805 Terries",
          b: "Mortgage",
          c: "P. Schwartzman",
          d: "Aug 04 2017",
          e: "",
          f: "Pending",
          g: "SG",
        },
        {
          a: "12900 Jones",
          b: "Release of court order and mortgage",
          c: "P. Schwartzman",
          d: "Sep 02 2017",
          e: "",
          f: "Completed",
          g: "SG",
        },
      ],
    };
  },
  methods: {
    checkbox: function ($event) {
      var _this = this;

      if (event.target.checked) {
        _this.checked++;
      } else {
        _this.checked--;
      }
    },
  },
};
</script>
