<template>
  <div id="app">
    <div class="wrapper mb-4">
      <header class="d-flex justify-content-between align-items-center">
        <a href="/" class="logo" title="LTSA styleguide homepage">
          <img class="logo-image" src="./assets/ltsa-logo.svg" alt="" />
        </a>
        <h2 class="mb-0 mr-3">Legacy style guide</h2>
      </header>

      <primary-navigation></primary-navigation>

      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import PrimaryNavigation from "./components/PrimaryNavigation.vue";

export default {
  name: "app",

  components: {
    "primary-navigation": PrimaryNavigation,
  },
};
</script>

<style lang="scss">
@import "./scss/ltsa-styles.scss";
</style>

<style lang="scss">
@import "../node_modules/bootstrap/scss/_functions.scss";
@import "./scss/ltsa-styles/_ltsa-variables.scss";
@import "../node_modules/bootstrap/scss/_variables.scss";
@import "../node_modules/bootstrap/scss/_mixins.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.wrapper {
  position: relative;
}

main {
  background: $white;
}

header {
  width: 100%;
  overflow: hidden;
  background: $gray-200;
}

.logo {
  display: inline-block;
  position: relative;
  padding: 16px 0;
  margin-left: 32px;
  z-index: 2;
  border-bottom: none;

  &:before {
    content: "";
    position: absolute;
    background: #fff;
    top: 0;
    bottom: 0;
    left: -80px;
    right: -60px;
    z-index: -1;
    transform: skewX(-40deg);
  }
}

.logo-image {
  width: auto;
  height: 28px;
}

// main {
//   margin-top: 5rem;
// }

.main--home {
  margin-top: 2rem;
}

h4 {
  a {
    border-bottom: none;
  }
}

.sg-grid__home {
  .col-md-4 {
    margin-bottom: 2rem;
  }
}

.section__heading {
  margin-bottom: 1rem;
  font-size: $h5-font-size;
}

// Page heading
.sg-page__heading {
  padding-bottom: 2rem;
}

// Component
.sg-component {
  display: flex;
  margin-bottom: 3rem;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.sg-component__guide {
  flex: 1;
  flex-basis: 25%;
  margin: 0 3rem;
  font-size: 13px;
  color: $gray-700;

  @include media-breakpoint-down(md) {
    max-width: 100%;
    margin: 2rem 0;
  }
}

.sg-component__content {
  flex: 1;
  flex-basis: 75%;
}

.sg-component__title {
  padding: 1rem 2rem;
  border-bottom: 1px solid $gray-300;
  font-weight: 700;
}

.sg-component__view {
  padding: 2rem;
  border: 1px solid #c9d4db;

  & > *:first-child {
    margin-top: 0;
  }

  &.sg-component__view--buttons {
    .btn + .btn {
      margin-left: 3px;
    }
  }
}

.sg-grid-bg__head {
  height: 4rem;
  margin-bottom: 1rem;
  // background-color: $brand-tertiary;
}

.sg-grid-bg__foot {
  height: 4rem;
  margin-top: 1rem;
  background-color: $gray-300;
}

.sg-grid-bg__col {
  height: 10rem;
  background-color: $gray-500;
}

.sg-grid-bg__col--light {
  background-color: $gray-400;
}

.sg-component__toggle {
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: $gray-500;
  background: $gray-200;
  border: none;
  cursor: pointer;
  user-select: none;

  &:hover,
  &:active {
    color: $link-color;
  }
}

pre {
  display: none !important;

  &.is-visible {
    display: block !important;
  }
}

.is-loading {
  .close--loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
  }
}

@include media-breakpoint-down(sm) {
  .logo {
    margin-left: 18px;
    padding: 8px 0;

    &:before {
      right: -40px;
    }
  }

  .logo-image {
    width: auto;
    height: 46px;
  }
}

// Colours

.card-color {
  width: 100%;
  height: 100px; // 6rem
}

.card-block {
  padding: 10px 0;
}

.card-color--brand-primary {
  background-color: $brand;
}

.card-color--brand-secondary {
  background-color: $green;
}

.card-color--brand-tertiary {
  background-color: $tertiary;
}

.card-color--black {
  background-color: $black;
}

.card-color--grey-darker {
  background-color: $gray-600;
}

.card-color--grey-dark {
  background-color: $gray-500;
}

.card-color--grey {
  background-color: $gray-400;
}

.card-color--grey-light {
  background-color: $gray-300;
}

.card-color--grey-lighter {
  background-color: $gray-200;
}

.card-color--grey-lightest {
  background-color: $gray-100;
}

.card-color--white {
  background-color: $white;
}

.card-color--link {
  background-color: $link-color;
}

.card-color--hover {
  background-color: #3b9cef;
}

.card-color--success {
  background-color: $success;
}

.card-color--error {
  background-color: $danger;
}

.card-color--warning {
  background-color: $warning;
}

/**
   * prism.js default theme for JavaScript, CSS and HTML
   * Based on dabblet (http://dabblet.com)
   * @author Lea Verou
   */

code[class*="language-"],
pre[class*="language-"] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  font-size: 0.9rem;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: $gray-200;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  text-shadow: none;
  background: $gray-200;
}

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

/* Code blocks */
pre[class*="language-"] {
  padding: 0 2em;
  margin: 0;
  overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: $gray-100;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #a67f59;
  background: hsla(0, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function {
  color: #dd4a68;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

pre.code-toolbar {
  position: relative;
}

pre.code-toolbar > .toolbar {
  position: absolute;
  top: 0.3em;
  right: 0.2em;
  transition: opacity 0.3s ease-in-out;
}

pre.code-toolbar:hover > .toolbar {
  opacity: 1;
}

pre.code-toolbar > .toolbar .toolbar-item {
  display: inline-block;
}

pre.code-toolbar > .toolbar a {
  cursor: pointer;
}

pre.code-toolbar > .toolbar button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
}

pre.code-toolbar > .toolbar a,
pre.code-toolbar > .toolbar button,
pre.code-toolbar > .toolbar span {
  color: $gray-600;
  font-size: 0.8em;
  padding: 0 0.5em;
}

pre.code-toolbar > .toolbar a:hover,
pre.code-toolbar > .toolbar a:focus,
pre.code-toolbar > .toolbar button:hover,
pre.code-toolbar > .toolbar button:focus,
pre.code-toolbar > .toolbar span:hover,
pre.code-toolbar > .toolbar span:focus {
  color: inherit;
  text-decoration: none;
}
</style>
