<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">A1 release</h2>

              <div class="d-flex align-items-center">
                <small>
                  <span class="text-muted text-small">Application Type: </span>
                  <strong>Application Type</strong>
                </small>
                <button class="btn btn-secondary btn-sm ml-2">Change</button>
              </div>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">
            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/applicant">Applicant</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/pid-legal-description">Description of Land</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/transferor">
                      Transferor
                      <span class="badge badge-pill badge-danger ml-1">1</span>
                    </router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">
                      Transferee
                      <span class="badge badge-pill badge-warning ml-1">1</span>
                    </a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/transfer-details">Transfer Details</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/execution">Execution</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Informational Document</a>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/form-validation">Form Validation</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/electronic-instrument">Electronic Instrument</router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9 col-lg-8">
              <h2 class="mb-3">
                Form Validation
                <a href="#" data-toggle="popover" data-placement="top" data-title="What is a Validation Error?" data-content="A technical or legal requirement that must be fixed beforesubmitting." v-popover>
                  <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                </a>
              </h2>
              <p class="lead">This is a summary of any errors or warnings that appeared in your application.</p>

              <!-- Summary  -->
              <div class="card bg-light mb-4">
                <div class="card-body">
                  <table>
                    <tr>
                      <td class="text-nowrap align-top pr-3">
                        <svg class="icon icon-error text-danger mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-error"></use></svg> Errors: <strong>1</strong>
                      </td>
                      <td class="pb-2"><small>All errors must be addressed before you can E-sign a document or submit a package.</small></td>
                    </tr>
                    <tr>
                      <td class="text-nowrap align-top pr-3">
                        <svg class="icon icon-warning text-warning mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-warning"></use></svg> Warnings: <strong>1</strong>
                      </td>
                      <td><small>Warnings will allow you to E-sign a document or submit a package, but you must acknowledge whether you will fix or override them.</small></td>
                    </tr>
                  </table>
                </div>
              </div>

              <!-- Error -->
              <div class="card mb-4">
                <div class="card-header bg-light">
                  <h4 class="mb-0">Transferor</h4>
                </div>
                <ul class="list-group list-group-flush mb-0">
                  <li class="list-group-item">
                    <div class="d-flex">
                      <svg class="icon icon-error text-danger"><use xlink:href="/icons/symbol-defs.svg#icon-error"></use></svg>
                      <div class="ml-2">
                        <p>The last name of the Individual Transferor field cannot be left blank.</p>
                        <button class="btn btn-md btn-tertiary">Fix</button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Warning -->
              <div class="card mb-4">
                <div class="card-header bg-light">
                  <h4 class="mb-0">Transferee</h4>
                </div>
                <ul class="list-group list-group-flush mb-0">
                  <li class="list-group-item">
                    <div class="d-flex">
                      <svg class="icon icon-error text-warning"><use xlink:href="/icons/symbol-defs.svg#icon-warning"></use></svg>
                      <div class="ml-2">
                        <p>Warning - Fractional interest in incomplete in a Transferee (or Lender) Group. [70243]</p>

                        <div class="d-flex align-items-center">
                          <button :disabled="override" class="btn btn-md btn-tertiary mr-2">Fix</button>
                          <div class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" id="overrideWarning" v-model="override" />
                            <label class="custom-control-label" for="overrideWarning">Override warning</label>
                          </div>
                        </div>

                        <!-- Override warning -->
                        <template v-if="overrideAdded == false">
                          <form class="mt-2" action="" v-if="override == true">
                            <div class="form-group">
                              <label for="overridermation">
                                Briefly describe your reason to override this warning. (optional)
                                <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" class="text-tertiary">
                                  <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg> </a
                                ><br />
                                Do not enter personal information.
                              </label>
                              <textarea class="form-control" name="" id="" cols="30" rows="2" v-model="overrideReason"></textarea>
                            </div>
                            <button class="btn btn-md btn-tertiary" @click.prevent="saveInfo()" type="submit">Done</button>
                            <button class="btn btn-md btn-link" @click.prevent="override = false">Cancel</button>
                          </form>
                        </template>

                        <div class="mt-2" v-if="overrideAdded == true">
                          <strong>Reason for override</strong>
                          <p class="mb-0">{{ overrideReason }}</p>
                          <a href="#" @click.prevent="overrideAdded = false">Edit</a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Multiple problems -->
              <div class="card mb-4">
                <div class="card-header bg-light">
                  <h4 class="mb-0">General</h4>
                </div>
                <ul class="list-group list-group-flush mb-0">
                  <li class="list-group-item">
                    <div class="d-flex">
                      <svg class="icon icon-error text-danger">
                        <use xlink:href="/icons/symbol-defs.svg#icon-error"></use>
                      </svg>
                      <div class="ml-2">
                        <p>A Transferor Name is missing in the Execution fields or the name in the Execution does not match the Transferor Name in the Transferor fields. [70858]</p>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="d-flex">
                      <svg class="icon icon-error text-warning"><use xlink:href="/icons/symbol-defs.svg#icon-warning"></use></svg>
                      <div class="ml-2">
                        <p>A Transferor Name is missing in the Execution fields or the name in the Execution does not match the Transferor Name in the Transferor fields. [70858]</p>
                        <button class="btn btn-md btn-tertiary">Fix</button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Action buttons -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">
                    Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PackageHeader from "../../../components/PackageHeader.vue";
import PackageNavigation from "../../../components/PackageNavigation.vue";

export default {
  components: {
    "package-header": PackageHeader,
    "package-navigation": PackageNavigation,
  },

  data() {
    return {
      overrideReason: "",
      overrideReason: "",
      override: false,
      overrideAdded: false,
    };
  },

  methods: {
    saveInfo: function () {
      this.overrideReason = this.overrideReason;
      this.overrideAdded = true;
    },
  },
};
</script>
