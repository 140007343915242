<template>
  <main>
    <package-header-deferred title="Package Deferred" sub-title="12764 Smith"></package-header-deferred>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2">
          <!-- Package navigation hamburger -->
          <button class="btn btn-secondary d-lg-none mb-3" data-toggle="collapse" data-target="#package-nav" aria-expanded="false" aria-controls="package-nav">
            <svg class="icon icon-menu"><use xlink:href="/icons/symbol-defs.svg#icon-menu"></use></svg>
            <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col">
          <div class="alert alert-info" role="alert">
            <div class="alert-icon">
              <svg class="icon icon-info"><use xlink:href="/icons/symbol-defs.svg#icon-info"></use></svg>
            </div>
            <div class="d-lg-flex align-items-center justify-content-between">
              <strong class="d-block mr-2 mb-2 mb-lg-0">This package is scheduled to be submitted on Janaury 31, 2025 at 6:10 AM.</strong>
              <button class="btn btn-md btn-tertiary text-nowrap">Cancel Scheduled Submission</button>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-end">
            <!-- Table toolbar -->
            <div>
              <div class="btn-toolbar sticky-top" v-show="checked > 0">
                <div class="btn-group">
                  <svg class="icon icon-corner-left-down"><use xlink:href="/icons/symbol-defs.svg#icon-corner-left-down"></use></svg>
                </div>

                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-md btn-secondary">Download</button>
                  <button type="button" class="btn btn-md btn-secondary">Print</button>
                  <button type="button" class="btn btn-md btn-danger">Delete</button>
                </div>
              </div>
            </div>
          </div>

          <!-- Package Management table -->
          <div class="sticky-table">
            <table class="table table-hover table-stacked table-select-row">
              <thead class="bg-medium">
                <tr>
                  <th scope="row">
                    <input type="checkbox" id="th" />
                  </th>
                  <th></th>
                  <th class="d-none d-md-table-cell" scope="col" width="105">Sequence</th>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Modified</th>
                  <th scope="col">Status</th>
                  <th class="text-md-right" scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableItems" :key="index">
                  <td scope="row">
                    <input type="checkbox" :id="index" @click="checkbox($event)" />
                  </td>
                  <td class="d-none d-md-table-cell">
                    <span class="td-content">
                      <svg class="icon icon-sequence text-tertiary"><use xlink:href="/icons/symbol-defs.svg#icon-sequence"></use></svg>
                    </span>
                  </td>
                  <td data-header="Sequence">
                    <span class="td-content">
                      <input type="text" class="form-control form-control-sm text-center" style="width: 4rem; height: 1.7rem" v-bind:value="index + 1" />
                    </span>
                  </td>
                  <td data-header="Name">
                    <span class="td-content">
                      <a href="#">{{ item.b }}</a>
                    </span>
                  </td>
                  <td data-header="Application">
                    <span class="td-content">{{ item.c }}</span>
                  </td>
                  <td data-header="Modified">
                    <span class="td-content">{{ item.d }}</span>
                  </td>
                  <td data-header="Status" v-html="item.e"></td>
                  <td data-header="Actions" class="text-md-right">
                    <div class="btn-group">
                      <router-link class="btn btn-secondary btn-sm" to="/pages/electronic-instrument"> Actions </router-link>
                      <button type="button" class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="javascript:void(0)">View form</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Table help -->
          <button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#application-help" aria-expanded="false" aria-controls="application-help">
            <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
            How to use this table
          </button>
          <div id="application-help" class="collapse" aria-labelledby="headingOne" data-parent="#application-help">
            <div class="collapse-body">Collapse content</div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Package Management"></help-panel>
  </main>
</template>

<script>
import PackageHeaderDeferred from "../../components/PackageHeaderDeferred.vue";
import PackageNavigation from "../../components/PackageNavigation.vue";
import HelpPanel from "../../components/HelpPanel.vue";

export default {
  components: {
    "package-header-deferred": PackageHeaderDeferred,
    "package-navigation": PackageNavigation,
    "help-panel": HelpPanel,
  },

  data() {
    return {
      checked: 0,
      sequenced: false,
      tableItems: [
        {
          a: "1",
          b: "A1 release",
          c: "Release",
          d: "Sep 23 2017",
          e: '<span class="td-content">E-signed</span>',
        },
        {
          a: "2",
          b: "A2 release",
          c: "Release",
          d: "Sep 22 2017",
          e: "Drafted",
        },
        {
          a: "3",
          b: "Mortgage Caso",
          c: "Create Mortgage",
          d: "Sep 21 2017",
          e: "Drafted",
        },
      ],
    };
  },
  methods: {
    checkbox: function ($event) {
      var _this = this;

      if (event.target.checked) {
        _this.checked++;
      } else {
        _this.checked--;
      }
    },
  },
};
</script>
